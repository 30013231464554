import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";


import NavBar from './navbar'
import Footer from './footer'
import Card from './card'

const Company = () => {

    useEffect(()=>{
          document.title = 'Company';
          window.scrollTo(0,0);
        },[])

    return (

                <div className="nk-app-root ">
                                    <NavBar/>
                                    <div style={{minHeight: '70px'}}></div>
                                   <main class="nk-pages">
                                               <section class="section has-shape has-mask" style={{paddingBottom: '0px'}}>
                                                   <div class="nk-shape bg-shape-blur-m mt-8 start-50 top-0 translate-middle-x"></div>
                                                   <div class="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                                                   <div class="container">
                                                       <div class="section-head">
                                                           <div class="row justify-content-center text-center">
                                                               <div class="col-lg-9 col-xl-8 col-xxl-7">
                                                                   <h6 class="overline-title text-primary">About Us</h6>
                                                                   <h2 class="title h1">The Story Behind Our Platform</h2>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div class="section-content">
                                                           <div class="row g-gs justify-content-center align-items-center flex-lg-row-reverse">
                                                               {/*<div class="col-lg-6 col-md-11">
                                                                   <div class="block-gfx ps-xxl-5">
                                                                       <img class="w-100" src="images/gfx/about.png" alt=""/>
                                                                   </div>
                                                               </div>*/}
                                                               <div class="col-lg-12">
                                                                   <div class="block-text">
                                                                      <ul class="list gy-3">
                                                                           <li>
                                                                             <p >Welcome to Faceview, where innovation meets human connection in the world of recruitment.
                                                                                Founded with a vision to revolutionize the hiring process, we embarked on a journey that has redefined how organizations discover, connect, and engage with talent.</p>

                                                                           </li>
                                                                         <li><p>
                                                                         At Faceview, we are a team of passionate individuals dedicated to simplifying and enhancing the recruitment experience.
                                                                         Our diverse backgrounds and expertise converge to create a platform that empowers recruiters, hiring managers, and organizations to make informed decisions and build exceptional teams.</p></li>


                                                                           <li><p>Our mission is to bridge the gap between talent and opportunity. We believe that every connection made through our platform is a step towards building stronger, more dynamic teams. Through cutting-edge technology and a commitment to user-centric design, we strive to make hiring not just a process but a strategic journey.</p></li>
                                                                                                                                                     <li><p>We pride ourselves on innovation, with features like AI-generated questions, collaborative team reviews, and scalable interview processes. These innovations are designed to streamline your hiring, casting, and onboarding needs, making your journey with us both efficient and rewarding.</p></li>
                                                                       </ul>
                                                                       <ul class="btn-list btn-list-inline gy-0">
                                                                           <li><a href="//app.faceview.io/trial" class="btn btn-lg btn-primary"><span>Sign up for Free</span><em class="icon ni ni-arrow-long-right"></em></a></li>
                                                                       </ul>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </section>
                                               {/*<section class="section section-sm section-0">
                                                   <div class="container">
                                                       <div class="section-head">
                                                           <div class="row justify-content-center text-center">
                                                               <div class="col-lg-9 col-xl-8 col-xxl-6">
                                                                   <h2 class="title">Who uses our product</h2>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div class="section-content">
                                                           <ul class="list list-row justify-content-center justify-content-lg-between gx-5 gy-4">
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-a.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-b.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-c.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-d.png" alt=""/>
                                                               </li>
                                                               <li>
                                                                   <img class="h-2rem" src="images/brands/72-e.png" alt=""/>
                                                               </li>
                                                           </ul>
                                                       </div>
                                                   </div>
                                               </section>*/}
                                               <section class="section section-bottom-0 has-shape">
                                                   <div class="nk-shape bg-shape-blur-m ms-n8 start-50 top-50 ms-n25 translate-middle-y"></div>
                                                   <div class="container">
                                                       <div class="section-content">
                                                           <div class="row gx-gs gy-6 justify-content-center align-items-center">
                                                               <div class="col-xl-5 col-lg-8">
                                                                   <div class="block-text pe-xxl-7 text-center text-xl-start">
                                                                       <h2 class="title">Your Gateway To Video Interviews</h2>
                                                                       <p class="lead">
                                                                        We understand that the world doesn't operate on a 9-to-5 schedule. That's why we've built a platform that allows teams to collaborate asynchronously, breaking free from the limitations of real time meetings.
                                                                        Join us on this journey to transform the way teams collaborate. Explore the possibilities, break free from the ordinary, and experience collaboration without limits.
                                                                        </p>
                                                                       <ul class="btn-list btn-list-inline gy-0">
                                                                           <li><a href="//app.faceview.io/trial" class="btn btn-lg btn-primary"><span>Sign up for Free</span><em class="icon ni ni-arrow-long-right"></em></a></li>
                                                                       </ul>
                                                                   </div>
                                                               </div>
                                                               <div class="col-xl-7">
                                                                   <div class="row">
                                                                       <div class="col-sm-6">
                                                                           <div class="card rounded-4 border-0 shadow-sm">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-primary rounded-3">
                                                                                               <em class="icon ni ni-globe"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                       <div class="feature-text">
                                                                                         <h3 class="title">Global Talent Access</h3>
                                                                                         <p>Overcome geographical barriers with FaceView. Conduct interviews with candidates from around the world, enabling you to tap into a diverse pool of talent without the limitations of physical proximity</p>
                                                                                     </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                           <div class="card rounded-4 border-0 shadow-sm mt-gs">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-success rounded-3">
                                                                                               <em class="icon ni ni-spark"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                       <div class="feature-text">
                                                                                           <h3 class="title">AI-Powered Questions</h3>
                                                                                           <p>Our AI-powered platform generates intelligent and relevant questions that spark insightful discussions.
                                                                                           Welcome to a new era of meaningful engagement.
                                                                                           </p>
                                                                                       </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                       <div class="col-sm-6 mt-5">
                                                                           <div class="card rounded-4 border-0 shadow-sm">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-info rounded-3">
                                                                                               <em class="icon ni ni-dashboard"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                       <div class="feature-text">
                                                                                           <h3 class="title">Your Own Job Board</h3>
                                                                                           <p> Our platform features an integrated Job Board, enabling employers to showcase all the open positions within the organization, making it easy for candidates to apply through customized application forms.
                                                                                                                                              </p>
                                                                                       </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                           <div class="card rounded-4 border-0 shadow-sm mt-gs">
                                                                               <div class="card-body">
                                                                                   <div class="feature">
                                                                                       <div class="feature-media">
                                                                                           <div class="media media-middle media-lg text-white bg-primary rounded-3">
                                                                                               <em class="icon ni ni-users"></em>
                                                                                           </div>
                                                                                       </div>
                                                                                      <div class="feature-text">
                                                                                         <h3 class="title">Collaborative Decision Making</h3>
                                                                                         <p>Foster teamwork in your hiring process. FaceView allows team members to review candidate responses collaboratively, share feedback, and make well-informed decisions collectively.</p>
                                                                                     </div>
                                                                                   </div>
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </section>
                                               <section class="section section-bottom-0 has-shape">
                                                   <div class="nk-shape bg-shape-blur-m mt-n3 start-50 top-0 translate-middle-x"></div>
                                                   <div class="container">
                                                       <div class="section-head">
                                                           <div class="row justify-content-center text-center">
                                                               <div class="col-lg-8 col-xl-7 col-xxl-6">
                                                                   <h6 class="overline-title text-primary">Careers</h6>
                                                                   <h2 class="title">View Job Openings</h2>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div class="section-content">
                                                           <div class="row g-gs justify-content-center align-items-center">
                                                                <iframe height="1000" src="https://jobs.faceview.io/faceview?mode=embed" title="W3Schools Free Online Web Tutorials"></iframe>

                                                           </div>
                                                       </div>
                                                   </div>
                                               </section>
                                               <Card/>
                                           </main>
                                    <Footer/>
                                </div>

    )
}

export default Company;