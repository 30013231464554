import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import NavBar from './navbar'
import Footer from './footer'

const UseCases = () => {

    useEffect(()=>{
      document.title = 'Use Cases';
      window.scrollTo(0,0);
    },[])

    return (

                <div className="nk-app-root ">
                <NavBar/>
                <div style={{minHeight: '70px'}}></div>
                <main class="nk-pages">
                <section class="section section-bottom-0 has-shape has-mask">
                <div class="nk-shape bg-shape-blur-m mt-8 start-50 translate-middle-x"></div>
                <div class="nk-mask bg-pattern-dot bg-blend-around mt-n5 mb-10p mh-50vh"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-8">
                                <h6 class="overline-title text-primary">Use cases</h6>
                                <h1 class="title">From Job Postings to Top Talent <span class="text-primary">Solutions for Every Business</span></h1>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row text-center g-gs">
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-flag"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Initial Screenings</h3>
                                                <p>Streamline your recruitment process by using video interviews for initial screenings. Evaluate candidate's
                                                 communication skills, professionalism, and cultural fit efficiently, allowing you to shortlist the most promising candidates for further stages</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-globe"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Job Interviews</h3>
                                                <p class="font-bold">Connect with talent globally without the need for in person interviews.Our platform allows candidates to respond to interview questions at their convenience.
                                                Employers can review candidate's responses at their own pace and make informed decisions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-video"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Video Auditions</h3>
                                                <p>Simplify the casting process for your projects. Conduct video auditions to get a better sense of an actor's performance, personality, and suitability for your project. Save time and resources by narrowing down your choices before in person auditions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-briefcase"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Business Inquiries</h3>
                                                <p class="font-bold">
                                                Effortlessly exchange information, clarify doubts, and make informed decisions. Whether it's procurement details, partnership queries, or general business matters, streamline communication through asynchronous video sessions
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-user"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Status Calls</h3>
                                                <p>Transform Status Calls with our platform's asynchronous sessions. Leaders can effortlessly request team updates, track progress, and make informed decisions, all without the constraints of real time schedules</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="card card-hover-translate rounded-4 border-0 shadow-tiny h-100 ">
                                    <div class="card-body">
                                        <div class="feature">
                                            <div class="feature-media">
                                                <div class="media media-middle media-xl text-bg-success-soft rounded-pill">
                                                    <em class="icon ni ni-diamond"></em>
                                                </div>
                                            </div>
                                            <div class="feature-text text-base">
                                                <h3 class="title">Ask an Expert</h3>
                                                <p>Tap into Collective Wisdom! With our platform, effortlessly seek guidance from industry specialists. Pose questions, receive expert insights asynchronously, and elevate your projects with expert guidance, all on your own schedule</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
                                     </main>
                                    <Footer/>
                                </div>

    )
}

export default UseCases;