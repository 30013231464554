import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import NavBar from './navbar'
import Footer from './footer'


const Faq = () => {

    useEffect(()=>{
          document.title = 'Faq';
          window.scrollTo(0,0);
        },[])

    return (

                <div className="nk-app-root ">
                    <NavBar/>
                    <div style={{minHeight: '70px'}}></div>
                    <main class="nk-pages">
                    <section className="section section-bottom-0">
                                            <div className="container">
                                                <div className="section-head">
                                                    <div className="row justify-content-center text-center">
                                                        <div className="col-xl-8">
                                                            <h2 className="title">Frequently Asked Questions</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="section-content">
                                                    <div className="row g-gs justify-content-center">
                                                        <div className="col-xl-9 col-xxl-8">
                                                            <div className="accordion accordion-flush accordion-plus-minus accordion-icon-accent" id="faq-1">
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#faq-1-1"> What is FaceView? </button>
                                                                    </h2>
                                                                    <div id="faq-1-1" className="accordion-collapse collapse show" data-bs-parent="#faq-1">
                                                                        <div className="accordion-body">FaceView is a SaaS platform designed to simplify the hiring process by allowing employers to create job postings and evaluate candidates through asynchronous video interviews. It eliminates the need for live interviews, giving candidates the flexibility to complete their interviews on their own schedule. </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-2"> How do asynchronous video interviews work?</button>
                                                                    </h2>
                                                                    <div id="faq-1-2" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                          <div className="accordion-body">
                                                                          Asynchronous video interviews are conducted without an interviewer present. Instead, candidates are shown a set of pre-recorded questions on their screen and respond as if they were in a real interview. The candidate can complete the interview at their own convenience, making it easier for employers to evaluate multiple candidates efficiently. </div>
                                                                     </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-3">  How does FaceView help employers save time? </button>
                                                                    </h2>
                                                                    <div id="faq-1-3" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                        <div className="accordion-body"> FaceView allows employers to review interview recordings in a scrollable interface, eliminating the need for time-consuming scheduling and live interviews. Employers can focus on reviewing candidates' responses at their own pace, schedule callback meetings with top-performing candidates, and make better, faster hiring decisions.</div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-4">  How do employers connect with top-performing candidates on FaceView? </button>
                                                                    </h2>
                                                                    <div id="faq-1-4" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                        <div className="accordion-body"> After candidates complete their asynchronous video interviews, employers can review the recordings, identify top performers, and schedule callback meetings to further evaluate those candidates. This streamlined process enables employers to connect with high-quality candidates without wasting time on less relevant ones. </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                     </main>
                    <Footer/>
                </div>

    )
}

export default Faq;