import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import Header from './header'
import Footer from './footer'
import PricingTable from './pricingtable'
import Card from './card'


const Home = () => {

    useEffect(()=>{
          document.title = 'FACEVIEW';
          window.scrollTo(0,0);
    },[])

    return (
        <div className="nk-app-root ">
                <Header/>

                <main className="nk-pages">
                    <section className="section section-bottom-0 has-shape">
                        <div className="nk-shape bg-shape-blur-a mt-8 start-50 top-0 translate-middle-x"></div>
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-8 col-xxl-7">
                                        <h6 className="overline-title text-primary">Get started for free</h6>
                                        <h2 className="title">Review Candidates and Schedule Callbacks with the Best Fits.</h2>
                                        <p className="lead">After candidates complete their interviews, you can review the video recordings in a scrollable interface.
                                        Schedule callback meetings with top performers by sending them a link to pick a time slot to connect via phone, onsite or a virtual meeting.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row text-center g-gs">
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-grid-plus"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Create a Job</h4>
                                                        <p>Create a job by providing a title, description and a set of interview questions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-globe"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Publish to Job Board</h4>
                                                        <p>Publish to the Job Board, allowing candidates to apply to the job.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-video"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Candidates take Video Interviews</h4>
                                                        <p>Candidates take their video interview, without the need for real-time availability</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-check"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Review Video Recordings</h4>
                                                        <p>Evaluate the candidates by reviewing the video recordings at your convenience</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">
                                                            <em className="icon ni ni-calendar"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Schedule Callback Meetings</h4>
                                                        <p>For candidates who stand out, schedule callback meetings, phone, onsite or virtual</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card rounded-4 border-0 shadow-tiny h-100">
                                            <div className="card-body">
                                                <div className="feature">
                                                    <div className="feature-media">
                                                        <div className="media media-middle media-xl text-bg-primary-soft rounded-4">

                                                            <em className="icon ni ni-users"></em>
                                                        </div>
                                                    </div>
                                                    <div className="feature-text">
                                                        <h4 className="title">Meet the Right Candidates</h4>
                                                        <p>Meet the right candidates and top fits, streamline your evaluation process.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section has-mask">
                        <div className="nk-mask bg-pattern-dot bg-blend-around mt-10p mb-3"></div>
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-8 col-xxl-7">
                                        <h6 className="overline-title text-primary">How it works</h6>
                                        <h2 className="title">How Callback Scheduling Works</h2>
                                        <p className="lead">For candidates who stand out, FaceView facilitates callback meetings via phone, in-person, or Google Meet.
                                        Employers can set their availability, and candidates can book directly from the shared calendar, ensuring seamless scheduling</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="row g-gs">
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Set Your Availabilility for Callback Meetings</h4>
                                                <p>
                                                 You can define the availability for callback meetings by selecting specific time slots during weekdays and setting a sliding validity period.

                                                  </p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/1.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Send Calendar to Candidates</h4>
                                                <p>Callback meeting can be scheduled with the best fits using three different modes: phone calls, in-person meetings, or virtual meetings.</p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/2.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="feature feature-inline">
                                            <div className="feature-text me-auto">
                                                <h4 className="title">Candidate Picks the Time Slot from the Calendar</h4>
                                                <p>Candidates receive an email with a link to the your calendar. They can select a suitable time slot from the available options.</p>
                                            </div>
                                            <div className="feature-decoration flex-shrink-0 ms-4">
                                                <img src="images/number/3.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-actions text-center">
                                <ul className="btn-list btn-list-inline gx-gs gy-3">
                                    <li><a href="//app.faceview.io/trial" className="btn btn-primary btn-lg"><span>Sign Up For Free</span></a></li>
                                    {/*<li><a href="#" className="btn btn-primary btn-soft btn-lg"><em className="icon ni ni-play"></em><span>See action</span></a></li>*/}
                                </ul>
                            </div>
                            <div className="section-content">
                                <div className="row gx-5 gy-6 align-items-center">
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="block-gfx pe-xl-5 pe-lg-3">
                                            <img className="w-100 rounded-4" src="images/gfx/feature/a.jpg" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="block-text">
                                            <h2 className="title">AI Generated Questions In Seconds</h2>
                                            <p>Generate questions for the interviews with Artificial Intelligence.</p>
                                            <ul className="list gy-3">
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Create an interview in less than 15 seconds.</span></li>
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Save hundreds of hours with our AI question generator.</span></li>
                                                <li><em className="icon ni ni-minus text-primary"></em><span>Modify the questions with the powerful editor.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section section-bottom-0 section-top-0">
                        <div className="container">
                            <div className="section-head">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-9 col-xl-7 col-xxl-6">
                                        <h6 className="overline-title text-primary">Pricing</h6>
                                        <h2 className="title">Plans That Best Suit Your Needs</h2>
                                        <p className="lead px-lg-10 px-xxl-9">With our simple plans, supercharge your team that helps your business.</p>
                                    </div>
                                </div>
                            </div>
                            <PricingTable/>
                        </div>
                    </section>

                            <section className="section section-bottom-0">
                                                                        <div className="container">
                                                                            <div className="section-head">
                                                                                <div className="row justify-content-center text-center">
                                                                                    <div className="col-xl-8">
                                                                                        <h2 className="title">Frequently Asked Questions</h2>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="section-content">
                                                                                <div className="row g-gs justify-content-center">
                                                                                    <div className="col-xl-9 col-xxl-8">
                                                                                        <div className="accordion accordion-flush accordion-plus-minus accordion-icon-accent" id="faq-1">
                                                                                            <div className="accordion-item">
                                                                                                <h2 className="accordion-header">
                                                                                                    <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#faq-1-1"> What is Faceview? </button>
                                                                                                </h2>
                                                                                                <div id="faq-1-1" className="accordion-collapse collapse show" data-bs-parent="#faq-1">
                                                                                                    <div className="accordion-body">Faceview is a SaaS platform designed to simplify the hiring process by allowing employers to create job postings and evaluate candidates through asynchronous video interviews. It eliminates the need for live interviews, giving candidates the flexibility to complete their interviews on their own schedule. </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="accordion-item">
                                                                                                <h2 className="accordion-header">
                                                                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-2"> How do asynchronous video interviews work?</button>
                                                                                                </h2>
                                                                                                <div id="faq-1-2" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                                                      <div className="accordion-body">
                                                                                                      Asynchronous video interviews are conducted without an interviewer present. Instead, candidates are shown a set of pre-recorded questions on their screen and respond as if they were in a real interview. The candidate can complete the interview at their own convenience, making it easier for employers to evaluate multiple candidates efficiently. </div>
                                                                                                 </div>
                                                                                            </div>
                                                                                            <div className="accordion-item">
                                                                                                <h2 className="accordion-header">
                                                                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-3">  How does Faceview help employers save time? </button>
                                                                                                </h2>
                                                                                                <div id="faq-1-3" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                                                    <div className="accordion-body"> Faceview allows employers to review interview recordings in a scrollable interface, eliminating the need for time-consuming scheduling and live interviews. Employers can focus on reviewing candidates' responses at their own pace, schedule callback meetings with top-performing candidates, and make better, faster hiring decisions.</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="accordion-item">
                                                                                                <h2 className="accordion-header">
                                                                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-1-4">  How do employers connect with top-performing candidates on Faceview? </button>
                                                                                                </h2>
                                                                                                <div id="faq-1-4" className="accordion-collapse collapse" data-bs-parent="#faq-1">
                                                                                                    <div className="accordion-body"> After candidates complete their asynchronous video interviews, employers can review the recordings, identify top performers, and schedule callback meetings to further evaluate those candidates. This streamlined process enables employers to connect with high-quality candidates without wasting time on less relevant ones. </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>

                    <Card/>
                </main>
                <Footer/>



            </div>
    )
}

export default Home;